<template>
  <div class="w-full overflow-hidden">
    <div class="flex items-center space-x-5">
      <div>
        <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
          Products list
        </h2>
      </div>
      <div>
        <router-link
          class="px-2 py-1 text-indigo-700 border border-indigo-700 rounded-xl dark:text-indigo-400 dark:border-indigo-400"
          to="/products/create">
          Create new Product
        </router-link>
      </div>
    </div>
    <div class="overflow-hidden border border-gray-300 rounded-xl dark:border-gray-600">
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase bg-gray-200 border-b dark:border-gray-700 dark:text-gray-400 dark:bg-gray-700">
              <th class="px-4 py-3">Name</th>
              <th class="px-4 py-3">Inventory</th>
              <th class="px-4 py-3">Price</th>
              <th class="px-4 py-3">Category</th>
              <th class="px-4 py-3">Cafe</th>
              <th class="px-4 py-3">Actions</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            <tr v-for="product in products" :key="product.nano_id" class="text-gray-700 dark:text-gray-400">
              <td class="px-4 py-3">
                <div class="flex items-center text-sm">
                  <div>
                    <p class="font-semibold">{{ product.name }}</p>
                  </div>
                </div>
              </td>
              <td class="px-4 py-3">
                <div class="flex items-center text-sm">
                  <div>
                    <p class="font-semibold">{{ product.has_inventory ? 'yes' : 'no' }}</p>
                  </div>
                </div>
              </td>
              <td class="px-4 py-3 text-xs">₹{{ product.price }}</td>
              <td class="px-4 py-3 text-sm">
                {{ product.category ? product.category.name : "Null" }}
              </td>
              <td class="px-4 py-3 text-sm">
                {{ product.cafe ? product.cafe.name : "null" }}
              </td>
              <td class="px-4 py-3">
                <div class="flex items-center space-x-4 text-sm">
                  <router-link
                    class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                    aria-label="Edit" :to="{
                      name: 'editproducts',
                      params: { nano_id: product.nano_id },
                    }">
                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z">
                      </path>
                    </svg>
                  </router-link>
                  <button
                    class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                    aria-label="Delete" @click="deleteProduct(product.nano_id)">
                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductsComponent",
  data() {
    return {};
  },
  mounted() {
    this.loadProducts();
  },
  computed: {
    ...mapGetters({
      products: ["product/getProducts"],
    }),
  },
  methods: {
    loadProducts() {
      this.$store.dispatch("product/getProducts");
    },
    deleteProduct(nano_id) {
      this.$store.dispatch("product/deleteProduct", { nano_id });
    },
  },
  filters: {
    humanDate(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const today = new Date(date);
      return today.toLocaleDateString("en-IN", options);
    },
  },
};
</script>

<style>

</style>
